.default {
  width: 830px;
  height: 40px;
  border-radius: 5px;
}

.check,
.radioGroup {
  margin-left: 0;
  margin-right: 6px;
  position: relative;
}

.radioGroup {
  display: flex;
  gap: .5em;
}
.radioGroup > label > input {
  margin-right: .5em;
}

.help table td,
.help table th {
  vertical-align: top;
  border-width: 1px;
  border-style: solid;
}
