.main {
  flex: 1 1 auto;
  display: flex;
  margin: 3px;
  min-height: 0;
  height: 100%;
  width:100%;
  min-width: 0;
  overflow: auto;
}

.preview.main {
  margin: 0;
}

.main.row {
  flex-direction: row;
}

.main.column {
  flex-direction: column;
}

.areaContainer {
  flex: 1 1 auto;
  display: flex;
  overflow: auto;
  height: 100%;
  width:100%;
}
.overflowAuto {
  overflow: auto;
}
.overflowHidden {
  overflow: hidden ;
}
