.overlay {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  overflow: hidden;
  backdrop-filter: contrast(20%) brightness(1.5);
}

.overlay > :global(.spinner) {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
