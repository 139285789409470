.fieldset {
  border: 1px solid grey;
  border-radius: 5px;
  padding: 1rem;
}

.fieldset legend {
  width: auto;
  padding: 0 .5rem;
  margin-bottom: 0;
  font-size: 95%;
  font-weight: 600;
}

.fieldset > div:last-child {
  margin-bottom: 0 !important;
}
