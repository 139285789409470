.mainContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: stretch;
  background-color: #f7f7f7;
}

.main {
  flex: 1;
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  padding: 64px 12px 12px;
}

.content {
  max-width: 900px;
  margin: 0 auto;
  padding: 32px 0;
}

.title {
  padding: 12px;
  font-size: 1.8em;
  font-weight: 300;
}

.tableContainer {
  padding: 12px;
}

table {
  width: 100%;
}

.tableContainer td,
.tableContainer th {
  padding: 12px 6px;
}

tbody tr {
  border-bottom: 1px solid lightgrey;
}

tbody tr:first-child {
  /* border-top: 1px solid lightgrey; */
}

.add {
  padding: 12px;
  /* border-bottom: 1px solid #e2e2e2; */
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  background-color: white;
  margin: 18px 6px;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.15);
}

/* .user:hover{
  background-color:#f3f3f3;
} */
