.main {
  display: flex;
  align-items: flex-start;
  padding: 8px;
  height: 100%;
}

.main select {
  max-width: 320px;
}

.label {
  padding-right: 12px;
  font-size: 0.9em;
  opacity: 0.5;
  padding: 2px 12px;
}

.radioContainerHor {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  cursor: pointer;
}
.radioContainerVer {
  display: table-row;
  float: left;
  margin-bottom: 4px;
  cursor: pointer;
  vertical-align: middle;
}

.rows .row {
  display: inline-block;
}
