/* reboot.css breaks this since the primereact selectors are not narrow enough */

button.p-button {
    border-radius: 6px;
    font-size: 14px;
}

button.p-button.p-button-rounded {
    border-radius: 2rem
}
