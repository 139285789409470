.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  /* padding: 6px; */
  /* background-color: rgb(242, 242, 242);  */
}

.areaContainer {
  flex: 1;
  display: flex;
  margin: 3px;
  min-height: 0;
  min-width: 0;
  height: 100%;
 

  /*border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;

  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 3px 4px 0px rgba(0,0,0,0.15);
  */
  overflow: auto;
}

.viewMode > .areaContainer {
  margin: 0 6px 6px;
  
}

.tabs {
  display: flex;
  padding: 12px 6px 0;
  border-bottom: 1px solid #d8d8d8;
  margin: 0 6px;
}

.tab {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border: 1px solid #d8d8d8;
  padding: 6px 16px;
  margin: 0 3px -1px;
  background-color: #eaeaea;
  cursor: pointer;
  box-shadow: inset 0px -7px 10px -9px rgba(152, 152, 152, 0.45);
}

.selected {
  border-bottom: none;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: none;
}

.refresh .tabs {
  padding: 18px 24px 0;
  margin: 0;
  border-width: 2px;
  border-color: #2B9DD4;
  background-color: #E8F7FE;
}

.threeColorTemplate .tabs {
  padding: 18px 24px 0;
  margin: 0;
  border-width: 2px;
  background-color: var(--threeColorTemplate-background);
  border-bottom: 2px solid var(--threeColorTemplate-highlight);
}

.refresh .tab {
  opacity: 0.5;
  margin: 0 3px -2px;
  color: #2B9DD4;
  border-color: #2B9DD4;
  border-bottom: none;
  background: none;
}

.threeColorTemplate .tab {
  margin: 0 3px -2px;
  background-color: hsl(0,0%,100%);
  color: var(--threeColorTemplate-background);
  border-color: var(--threeColorTemplate-background);
  border-bottom: 2px solid var(--threeColorTemplate-highlight);
}

.refresh .selected, .refresh .tab:hover {
  opacity: 1;
  padding: 5px 15px 6px;
  border-width: 2px;
  background-color: #fff; 
  
}

.threeColorTemplate .selected, .threeColorTemplate .tab:hover {
  padding: 5px 15px 6px;
  border-width: 2px;
  font-weight: bold;
  color: var(--threeColorTemplate-highlight);
  border-color: var(--threeColorTemplate-highlight);
  border-bottom: none;
}

.refresh .areaContainer,
.threeColorTemplate .areaContainer {
  margin: 0;
  background-color: #fff; 
  /*background-color: rgb(242, 242, 242);  */
}


.carouselTabsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.carouselTabsContainer > .carouselButton {
  font-size: 2rem;
}

.carouselTabsContainer > .carouselItem {
  font-size: 0.8rem;
  color: lightgrey;
}

.carouselTabsContainer [data-selected=true] {
  color: #1976d2;
}
