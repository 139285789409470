.wrapper {
  /* position: fixed; */
  width: 100%;
  background-color: rgb(56, 56, 56);
  /* bottom: 0; */
  margin-top: 5px;
}

.main {
  opacity: 0.5;
  color: white;
  padding: 2px 32px;
  padding2: 12px 32px;
  max-width: 1120px;
  margin: 0 auto;
  flex-shrink: 0;
  /* height: 42px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.names div {
  font-size: 0.8em;
  font-weight: 300;
}

.commentMessage {
  text-align: right;
}

.email,
.email:hover {
  color: white;
  text-decoration: underline;
}
