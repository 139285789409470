.main {
  padding: 8px;
  overflow: auto;
}

.pathSegment {
  padding: 4px 0;
  cursor: pointer;
  line-height: 1.2em;
}

.pathSegment:hover {
  text-decoration: underline;
  color: #3510d6;
}

.refresh.main,
.threeColorTemplate.main {
  padding: 12px 0;
}

.refresh .pathSegment,
.threeColorTemplate .pathSegment {
  padding: 4px 8px;
}

.refresh .label,
.threeColorTemplate .label {
  min-width: none;
  overflow: hidden;
  text-overflow: ellipsis;
}

.refresh .pathSegment:hover {
  color: #2B9DD4;
  text-decoration: underline;
}

.threeColorTemplate .pathSegment:hover {
  color: var(--threeColorTemplate-highlight);
  text-decoration: underline;
}
