.button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;

    border: none;
    border-radius: 5px;
    font-size: 11pt;
    font-weight: 500;
    white-space: nowrap;
    padding: .4rem 1.5rem;
    cursor: pointer;
    user-select: none;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}
a.button {
    text-decoration: none;
}

/* variant primary */

.button.primary {
    background: #1c1259;
    color: white;
}

.button.primary:hover,
a.button.primary:hover {
    background: #3e3285;
    color: white;
}

.button.primary:active {
    background: #302571;
}

.button.primary:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(62, 50, 133, 0.3);
}

/* variant info */

.button.info {
    background: #6da4c3;
    color: white;
}

.button.info:hover,
a.button.info:hover {
    background: #85b2ce;
    color: white;
}

.button.info:active {
    background: #6395b0;
}

.button.info:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(154, 199, 222, 0.5);
}

/* variant outline */

.button.outline {
    color: #2a2a2a;
    background: white;
    border: 1px solid #bbbbbb;
}
.button.outline:hover,
a.button.outline:hover {
    background: #dedede;
    color: black;
}

.button.outline:active {
    background: #e3e3e3;
}

.button.outline:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(136, 136, 136, 0.3);
}