.fullscreen {
  width: 100%;
  height: 99%;
  border: none;
}

.fullwindow {
  width: 100%;
  height: 99%;
  border: none;
}
